window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

window.consentGranted = function(consent_option) {
    if(consent_option == 'all') {
        console.log('consent granted to all')
        gtag('consent', 'update', {
            'functionality_storage': 'granted',
            'analytics_storage': 'granted',
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'personalization_storage': 'granted',
            'security_storage': 'granted'
        });
    } else {
        console.log('consent granted to ' + consent_option)
        gtag('consent', 'update', {
            consent_option: 'granted',
        });
    }
}

window.consentDenied = function(consent_option) {
    console.log('consent denied to ' + consent_option)
    gtag('consent', 'update', {
        consent_option: 'denied',
    });
}

window.consentGiven = function() {
    localStorage.setItem('consent-given', '1');
}

window.consentCheck = function() {
    if(localStorage.getItem('consent-given') === '1') {
        console.log('Cookies consent already given!')
        return true;
    } else {
        console.log('Open cookie settings modal!')
        return false;
    }
}

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

import moment from 'moment'
window.moment = moment

import ApexCharts from "apexcharts"
window.ApexCharts = ApexCharts

import("./theme.js").then(function (theme) {
    theme.setTheme();
});
